import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Chip from '@mui/material/Chip';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import RefreshIcon from '@mui/icons-material/Refresh';
import dayjs from 'dayjs';
import WhatsappFormDialog from './WhatsappFormDialog';
import { TEMPLATE_ENDPOINT } from '../../config/urls';
import { useAuth } from '../../hooks/AuthProvider';
import { TextField } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Select } from '@mui/material';
import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const columns = [
  { id: 'template_name', label: 'Template Name' },
  { id: 'category', label: 'Category' },
  { id: 'language', label: 'Language' },
  { id: 'status', label: 'Status' },
  { id: 'template_id', label: 'Template ID' },
  { id: 'description', label: 'Description' },
  { id: 'created_at', label: 'Created Date' },
  { id: 'action', label: 'Action' },
];

export default function WhatsappTable({ refreshKey, dateInput, setTemplatesNamesData }) {
  const { getValidToken } = useAuth();
  const [rowCount, setRowCount] = useState(0);
  const [templatesData, setTemplateData] = useState([]);
  const [templatesModalState, settemplatesModalState] = useState({ isOpen: false, selectedClient: null, mode: 'add', jsonData: '' });
  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:${process.env.REACT_APP_DOMAIN_BACKEND_PORT}/api`;
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20
  });

  const captilazeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const languageOptions = [...new Set(templatesData.map(template => template.language))].map(language => (
    <MenuItem key={language} value={language}>{captilazeFirstLetter(language)}</MenuItem>
  ));

  const categoryOptions = [...new Set(templatesData.map(template => template.category))].map(category => (
    <MenuItem key={category} value={category}>{captilazeFirstLetter(category)}</MenuItem>
  ));

  // initilized state variables to store data
  const [searchQuery, setSearchQuery] = useState('');
  const [languageFilter, setLanguageFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [dateError, setDateError] = useState(null);

  const fetchData = async () => {
    let searchDateURL = '';
    if (dateInput) {
      let dateString = dayjs(dateInput).format("YYYY-MM-DD");
      searchDateURL = `&search_date=${dateString}`;
    }

    // Construct query parameters for server-side filtering and pagination
    const queryParams = new URLSearchParams({
      limit: controller.rowsPerPage.toString(),
      offset: (controller.serverSidePage * controller.rowsPerPage).toString(),
      search_query: searchQuery,
      language_filter: languageFilter,
      category_filter: categoryFilter,
      from_date: fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : '',
      to_date: toDate ? dayjs(toDate).format("YYYY-MM-DD") : ''
    });

    const token = await getValidToken();

    try {
      const response = await fetch(`${TEMPLATE_ENDPOINT}?${queryParams}${searchDateURL}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const jsonData = await response.json();

      console.log(jsonData);

      setTemplateData(jsonData.results);
      setTemplatesNamesData(jsonData.template_names);
      setRowCount(jsonData.count);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [
    controller.serverSidePage, 
    controller.rowsPerPage, 
    refreshKey, 
    dateInput, 
    searchQuery, 
    languageFilter, 
    categoryFilter, 
    fromDate, 
    toDate
  ]);

  // pagination
  const handlePageChange = (event, newPage) => {
    setController(prev => ({
      ...prev,
      page: newPage,
      serverSidePage: newPage  // Sync server-side page with UI page
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...prev => ({
        ...prev,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
        serverSidePage: 0  // Reset to first page
      })
    });
  };

  const handleDisableClick = (template) => {
    settemplatesModalState({ isOpen: true, selectedClient: template, mode: 'disable' });
  };

  const handleViewClick = (template) => {
    if (template.template_preview_json != "{}") {
      console.log("template json present")
      settemplatesModalState({ isOpen: true, selectedClient: template, mode: 'view', jsonData: template.template_preview_json });
    } else {
      console.error('No JSON found for template');
    }
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleLanguageFilterChange = (event) => {
    setLanguageFilter(event.target.value);
  };

  const handleCategoryFilterChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleFromDateChange = (newValue) => {
    if (toDate && newValue && dayjs(newValue).isAfter(toDate)) {
      setDateError('From Date must be less than or equal to To Date');
    } else {
      setDateError(null);
      setFromDate(newValue);
    }
  };

  const handleToDateChange = (newValue) => {
    if (fromDate && newValue && dayjs(newValue).isBefore(fromDate)) {
      setDateError('To Date must be greater than or equal to From Date');
    } else {
      setDateError(null);
      setToDate(newValue);
    }
  };

  const handleResetFilter = () => {
    setFromDate(null);
    setToDate(null);
    setLanguageFilter('');
    setCategoryFilter('');
    setSearchQuery('');
  };

  const handleRefresh = () => {
    setController({
      page: 0,
      rowsPerPage: 20
    });
    fetchData();
  };

  const filteredTemplates  = templatesData.filter((template) => {
    const searchQueryMatch = template.template_name.toLowerCase().includes(searchQuery.toLowerCase());
    const languageFilterMatch = languageFilter === '' || template.language === languageFilter.toLowerCase();
    const categoryFilterMatch = categoryFilter === '' || template.category === categoryFilter;
    const dateFilterMatch = (fromDate === null && toDate === null) || 
                            (new Date(template.created_at.split('/').reverse().join('-')) >= fromDate && 
                             new Date(template.created_at.split('/').reverse().join('-')) <= toDate);

    return searchQueryMatch && languageFilterMatch && categoryFilterMatch && dateFilterMatch;
  });

  return (
    <div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Search Template Name"
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel>Language</InputLabel> 
                <Select
                  value={languageFilter}
                  onChange={handleLanguageFilterChange}
                  displayEmpty
                  variant="outlined"
                  sx={{ minWidth: 120 }}
                  fullWidth
                >
                  <MenuItem value="english">English</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel> 
                <Select
                  value={categoryFilter}
                  onChange={handleCategoryFilterChange}
                  displayEmpty
                  fullWidth
                >
                  {/* <MenuItem value="">Category</MenuItem> */}
                  <MenuItem value="marketing">Marketing</MenuItem>
                  <MenuItem value="utility">Utility</MenuItem>
                  {/* {categoryOptions} */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DatePicker
                  label="From Date"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DatePicker
                  label="To Date"
                  value={toDate}
                  onChange={handleToDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <Button variant="outlined" onClick={handleResetFilter} >Reset</Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={handleRefresh} ><RefreshIcon /> Sync</Button>
            </Grid>
          </Grid>

        </Box>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left"
                    style={{ minWidth: column.minWidth, border: '1px solid rgba(224, 224, 224, 1)' }}
                  >
                    <h3>{column.label}</h3>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {templatesData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No match found
                  </TableCell>
                </TableRow>
              ) : (
                templatesData.map((template, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      <a 
                        href="#" 
                        onClick={(e) => {
                          e.preventDefault();
                          handleViewClick(template);
                        }}
                      >
                        {template.template_name}
                      </a>
                    </TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      {template.category === 'MARKETING' ? 
                        "Marketing" :
                        template.category === 'UTILITY' ?
                          "Utility" :
                          "Unknown"
                      }
                    </TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      {template.language === 'english' ? 
                        "English" :
                        template.language === 'hindi' ?
                          "Hindi" :
                          "Unknown"
                      }
                    </TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      {template.status === 'APPROVED' ? 
                        <Chip variant="outlined" color="success" label="APPROVED" size="small" /> :
                        template.status === 'REJECTED' ?
                          <Chip variant="outlined" color="primary" label="REJECTED" size="small" /> :
                          template.status === 'FAILED' ?
                            <Chip variant="outlined" color="warning" label="FAILED" size="small" /> :
                            <Chip variant="outlined" color="warning" label="PENDING" size="small" />
                      }
                    </TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      {template.event_id === "" || !template.event_id ? template.template_id : template.event_id}
                    </TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      {template.description}
                    </TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      {new Date(template.created_at).toLocaleDateString('en-GB')}
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      <Button variant="contained" onClick={() => handleDisableClick(template)}>Disable</Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={rowCount}
          rowsPerPage={controller.rowsPerPage}
          page={controller.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <WhatsappFormDialog
          modalState={templatesModalState}
          handleModalState={settemplatesModalState}
          selectedClient={templatesModalState.selectedClient}
        />
      </Paper>
    </div>
  );
}
