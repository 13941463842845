import React, {useState} from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import ZoneSearchInput from './ZonesSearchInput';
import ZonesTable from './ZonesTable';

function ZonesTab() {
    const [searchValue, setSearchValue] = useState("");
    const [refreshKey, setRefreshKey] = useState(0);
    
    const updateSearchValue = (value) => {
      setSearchValue(value);
    }


  return (
    <div>
      <Container maxWidth={false}>
        <Grid container alignItems="center" spacing={2} sx={{mb: 3}}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Zones
            </Typography>
          </Grid>
          <Grid item>
            <ZoneSearchInput updateSearchValue={updateSearchValue} updateRefreshKey={setRefreshKey} />
          </Grid>
        </Grid>
        <ZonesTable searchInput={searchValue} refreshKey={refreshKey} setRefreshKey={setRefreshKey} />
      </Container>
    </div>
  );
}

export default ZonesTab;
