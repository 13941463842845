import React, {useState} from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

// import ZoneAssignmentSearchInput from "../ZoneAssignmentSearchInput";
import ZoneAssignmentSearchInput from "./ZoneAssignmentSearchInput";
import ZonesAssignmentTable from './ZonesAssignmentTable';

function ZoneAssignmentsTab() {
    const [searchValue, setSearchValue] = useState("");
    const [refreshKey, setRefreshKey] = useState(0);
    
    const updateSearchValue = (value) => {
      setSearchValue(value);
    }

  return (
    <div>
      <Container maxWidth={false}>
        <Grid container alignItems="center" spacing={2} sx={{mb: 3}}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Zone Assignment
            </Typography>
          </Grid>
          <Grid item>
            <ZoneAssignmentSearchInput updateSearchValue={updateSearchValue} />
          </Grid>
        </Grid>
        <ZonesAssignmentTable searchInput={searchValue} />
      </Container>
    </div>
  );
}

export default ZoneAssignmentsTab;
