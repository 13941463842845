import React, { useState, useEffect, useRef } from 'react';

import {
    Select,
    MenuItem,
    Grid,
    Typography,
    Container,
    TextField,
    Button,
    Alert,
    IconButton,
    Autocomplete,
    RadioGroup, 
    Radio, 
    FormControlLabel,
    Divider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import MessageNode from "../Whatsapp/MessageNode";
import { TEMPLATE_ENDPOINT, TEMPLATE_BROADCAST_ENDPOINT, CAMPAIGN_LIST_ENDPOINT } from '../../config/urls';

import UploadCSV from './CampaignOptions/UploadCSV';
import SingleContact from './CampaignOptions/SingleContact';
import SelectSegment from './CampaignOptions/SelectSegment';

import axios from 'axios';

import { useAuth } from '../../hooks/AuthProvider';

const BroadcastTemplates = (props) => {

    const { getValidToken } = useAuth();
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [templateOptions, setTemplateOptions] = useState([]);
    const [templateData, setTemplateData] = useState(null);
    const [templatePreviewData, setTemplatePreviewData] = useState(null);
    const [apiData, setApiData] = useState(null);
    const [requiredFields, setRequiredFields] = useState({});
    const fileInputRef = useRef(null);

    // File upload
    const [mediaType, setMediaType] = useState('none');
    const [mediaFile, setMediaFile] = useState(null);

    const [requiredFieldsToRender, setRequiredFieldsToRender] = useState([]);
    const [formValues, setFormValues] = useState({});

    // Campaign
    const [showCampaignOptions, setShowCampaignOptions] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [campaignsData, setCampaignsData] = useState([]);

    // Option 1
    const [csvFile, setCsvFile] = useState(null);
    
    // Option 2
    const [campaignName, setCampaignName] = useState('');               // for both option 1 and 2
    const [campaignDescription, setCampaignDescription] = useState(''); // for both option 1 and 2
    const [selectedSegment, setSelectedSegment] = useState('Select a segment');
    const [selectedSegmentList, setSelectedSegmentList] = useState([]);
    const [phoneNumberList, setPhoneNumberList] = useState([]);
    
    // Option 3
    const [phoneNumber, setPhoneNumber] = useState('');

    const [alertObj, setAlertObj] = useState({
        active: false,
        msg: "",
        type: ""
    });

    const handleAlertClose = () => {
        setAlertObj({
        active: false,
        type: "",
        msg: ""
        });
    };

    const handleMediaFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setMediaFile(file);
        }
    };

    const handleDeleteMediaFile = () => {
        
        setMediaFile(null);
        
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }

        const syntheticEvent = {
            target: { value: selectedTemplate || '' }
        };
        handleTemplateChange(syntheticEvent);

    };

    const fetchTemplatesData = async () => {

        const token = await getValidToken();

        try {
        const response = await fetch(`${TEMPLATE_ENDPOINT}?limit=1000`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch templates data');
        }
        const jsonData = await response.json();

        // Filter approved marketing templates
        const approvedTemplates = {
            ...jsonData,
            results: jsonData.results.filter(template => template.status === 'APPROVED' && template.category === 'MARKETING')
        };

        console.log("GET Templates API response", jsonData);

        setApiData(approvedTemplates);
        setTemplateOptions(approvedTemplates.results.map(item => item.template_name));
        setRequiredFields(approvedTemplates.required_fields);
    
        } catch (error) {
        console.error('Error fetching templates data:', error.message);
        }
    };

    const fetchCampaignsData = async () => {
        
        const token = await getValidToken();

        try {
            const response = await fetch(CAMPAIGN_LIST_ENDPOINT + `?limit=1000`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        if (!response.ok) {
            throw new Error('Failed to fetch templates data');
        }
        const jsonData = await response.json();

        const campaignNames = jsonData.results.map(campaign => campaign.campaign_name.toLowerCase());
        console.log("campaignNames: ", campaignNames);
        setCampaignsData(campaignNames);
    
        } catch (error) {
        console.error('Error fetching templates data:', error.message);
        }
    };

    const FileUploadDetails = () => {
        if (!mediaFile) {
            return null;
        }

        templatePreviewData.headerFile = mediaFile;

        return (
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <Typography variant="body2">
                        File uploaded: {mediaFile.name}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton
                        aria-label="delete"
                        onClick={handleDeleteMediaFile}
                        size="small"
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    const getFileInput = (type) => {
        const fileTypes = {
            image: {
                accept: '.jpg, .png',
                label: 'Choose .JPG or .PNG*',
            },
            video: {
                accept: '.mp4',
                label: 'Choose .MP4 file*',
            },
            document: {
                accept: '.pdf',
                label: 'Choose .PDF file*',
            },
        };

        if (fileTypes[type]) {
            const { accept, label } = fileTypes[type];
            return (
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <input
                            ref={fileInputRef}
                            id="file-upload"
                            name="uploadedFile"
                            type="file"
                            accept={accept}
                            onChange={handleMediaFileChange}
                            style={{ display: 'none' }}
                            required
                        />
                        <label htmlFor="file-upload">
                            <Button
                                variant="contained"
                                component="span"
                                sx={{ marginTop: 1, marginBottom: 1 }}
                            >
                                {label}
                            </Button>
                        </label>
                    </Grid>
                </Grid>
            );
        }
        return null;
    };

    const handleTemplateChange = (event) => {
        const selectedTemplateName = event.target.value;
        setSelectedTemplate(selectedTemplateName);

        // Reset form values when switching back to "Select a template"
        if (selectedTemplateName === '') {
            setFormValues({});
            setRequiredFieldsToRender([]);
            setTemplatePreviewData(null);
            setMediaType('none');
            setMediaFile(null);
        } else {
            // Find the template preview data based on the selected template name
            const selectedTemplate = apiData?.results.find(item => item.template_name === selectedTemplateName);
            if (selectedTemplate) {
                setTemplatePreviewData(selectedTemplate.template_preview_json);
                setTemplateData(selectedTemplate.template_json);
                setFormValues({});
                setRequiredFieldsToRender([]);
                setMediaType('none');
                setMediaFile(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            } else {
                setTemplatePreviewData(null);
                setTemplateData(null);
                setFormValues({});
                setRequiredFieldsToRender([]);
                setMediaType('none');
                setMediaFile(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            }

            setMediaType('none');

            // Get the required fields for the selected template
            const requiredFieldsForTemplate = requiredFields[selectedTemplateName];
            if (requiredFieldsForTemplate) {
                const fieldsToRender = [];
                const updatedFormValues = { ...formValues };

                if (requiredFieldsForTemplate.includes('hparameter')) {
                    fieldsToRender.push(
                        <Grid container direction="column" key="hparameter">
                            <TextField xs={12} sx={{ marginTop: 2, width: '50%' }} name='hparameter' key="hparameter" label="Header Parameter" onChange={handleInputChange} required />
                        </Grid>
                    );
                    updatedFormValues.hparameter = '';
                }
                if (requiredFieldsForTemplate.includes('bparameter')) {
                    const bparameterCount = requiredFieldsForTemplate.filter(field => field === 'bparameter').length;
                    for (let i = 0; i < bparameterCount; i++) {
                        fieldsToRender.push(
                            <Grid container direction="column" key={`bparameter-${i}`}>
                                <TextField xs={12} sx={{ marginTop: 2, width: '50%' }} name={`bparameter-${i}`} key={`bparameter-${i}`} label={`Body Parameter ${i+1}`} onChange={handleInputChange} required />
                            </Grid>
                        );
                        updatedFormValues[`bparameter-${i}`] = '';
                    }
                }
                if (requiredFieldsForTemplate.includes('urlparameter')) {
                    fieldsToRender.push(
                        <Grid container direction="column" key="urlparameter">
                            <TextField xs={12} sx={{ marginTop: 2, width: '90%' }} name='urlparameter' key="urlparameter" label="URL Parameter" onChange={handleInputChange} required />
                        </Grid>
                    );
                    updatedFormValues.urlparameter = '';
                }
                if (requiredFieldsForTemplate.includes('imageparameter')) {
                    setMediaType('image');
                    fieldsToRender.push(
                        getFileInput('image')
                    );
                    updatedFormValues.imageparameter = '';
                }
                if (requiredFieldsForTemplate.includes('videoparameter')) {
                    setMediaType('video');
                    fieldsToRender.push(
                        getFileInput('video')
                    );
                    updatedFormValues.videoparameter = '';
                }
                if (requiredFieldsForTemplate.includes('documentparameter')) {
                    setMediaType('document');
                    fieldsToRender.push(
                        getFileInput('document')
                    );
                    updatedFormValues.documentparameter = '';
                }
                if (requiredFieldsForTemplate.includes('locationparameter')) {
                    fieldsToRender.push(
                        <>
                            <Grid container direction="column" key="locationparameter_name">
                                <TextField xs={12} sx={{ marginTop: 2, width: '90%' }} name='locationparameter_name' key="locationparameter_name" label="Location Name" onChange={handleInputChange} required />
                            </Grid>
                            <Grid container direction="column" key="locationparameter_address">
                                <TextField xs={12} sx={{ marginTop: 2, width: '90%' }} name='locationparameter_address' key="locationparameter_address" label="Location Address" onChange={handleInputChange} required />
                            </Grid>
                            <Grid container direction="column" key="locationparameter_latitude">
                                <TextField xs={12} sx={{ marginTop: 2, width: '90%' }} name='locationparameter_latitude' key="locationparameter_latitude" label="Location Latitude" onChange={handleInputChange} required />
                            </Grid>
                            <Grid container direction="column" key="locationparameter_longitude">
                                <TextField xs={12} sx={{ marginTop: 2, width: '90%' }} name='locationparameter_longitude' key="locationparameter_longitude" label="Location Longitude" onChange={handleInputChange} required />
                            </Grid>
                        </>
                    );
                    updatedFormValues.locationparameter_name = '';
                    updatedFormValues.locationparameter_address = '';
                    updatedFormValues.locationparameter_latitude = '';
                    updatedFormValues.locationparameter_longitude = '';
                }

                setFormValues(updatedFormValues);
                setRequiredFieldsToRender(fieldsToRender);
            } else {
                setFormValues({});
                setRequiredFieldsToRender([]);
                setMediaType('none');
            }
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        // setFormValues({ ...formValues, [name]: value });
        setFormValues((prevFormValues) => ({ ...prevFormValues, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (selectedTemplate === '') {
            return;
        }

        if (mediaType !== 'none' && !mediaFile) {
            setAlertObj({
                active: true,
                type: "error",
                msg: "File not uploaded"
            });
            return;
        }

        const payload = new FormData();
        payload.append('template_json', JSON.stringify(templateData));
        Object.keys(formValues).forEach(key => {
            payload.append(key, formValues[key]);
        });
        if (mediaFile) {
            payload.append('file', mediaFile);
        }

        // Option 1
        if (selectedCampaign === 'option1') {
            console.log("csvFile: ", csvFile);
            if (campaignName !== '' && csvFile) {
                // merge phone number into payload
                payload.append('campaign_option', selectedCampaign);
                payload.append('campaign_name', campaignName);
                payload.append('campaign_description', campaignDescription);
                payload.append('csv_file', csvFile);
            }
            else {
                setAlertObj({
                    active: true,
                    type: "error",
                    msg: "Please enter all the required fields"
                });
                return;
            }
        }

        // Option 2
        if (selectedCampaign === 'option2') {
            // if (phoneNumberList.length === 0) {
            //     setAlertObj({
            //         active: true,
            //         type: "error",
            //         msg: "No phone numbers found"
            //     });
            //     return;
            // }

            if (campaignName !== '' && selectedSegment !== '' && phoneNumberList.length > 0) {
                payload.append('campaign_option', selectedCampaign);
                payload.append('campaign_name', campaignName);
                payload.append('campaign_description', campaignDescription);
                payload.append('segment_name_list', JSON.stringify(selectedSegmentList));
            }
            else {
                setAlertObj({
                    active: true,
                    type: "error",
                    msg: "Please enter all the required fields"
                });
                return;
            }
        }

        // Option 3
        else if (selectedCampaign === 'option3') {
            if (phoneNumber !== '') {
                // merge phone number into payload
                payload.append('campaign_option', selectedCampaign);
                payload.append('phone_number', phoneNumber);
            }
            else {
                setAlertObj({
                    active: true,
                    type: "error",
                    msg: "Please enter a valid phone number"
                });
                return;
            }
        }

        console.log("Payload: ", payload);

        const token = await getValidToken();

        // send POST request to TEMPLATE_BROADCAST_ENDPOINT
        axios.post(TEMPLATE_BROADCAST_ENDPOINT, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
            .then(response => {
                console.log('POST API Response:', response);
                setAlertObj({
                    active: true,
                    type: "success",
                    msg: "Broadcast Complete"
                });
                setTimeout(() => {
                    // props.setShowCampaigns(true);
                    props.onNavigate('18');
                }, 3000);
            })
            .catch(error => {
                console.error('API Error:', error.response.data);
                setAlertObj({
                    active: true,
                    type: "error",
                    msg: `Broadcast Failed - ${error.response.status === 401 ? 'Unauthorized' : error.response.data.message}`
                });
            });

    };

    const handleCampaignClick = (event) => {
        event.preventDefault();
        
        if (event.target.checkValidity()) {
            setShowCampaignOptions(true);
        } else {
            // Trigger form validation display
            event.target.reportValidity();
        }
    };

    useEffect(() => {
        fetchTemplatesData();
        fetchCampaignsData();
        setRequiredFieldsToRender([...requiredFieldsToRender]);
    }, [mediaType, mediaFile, selectedTemplate, formValues, csvFile]);

    return (
        <Container maxWidth={false}>
            <div sx={{ padding: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={7}>
                        <div sx={{ padding: 2 }}>
                            
                            <form onSubmit={handleCampaignClick}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                        Select Template
                                    </Typography>
                                    <Autocomplete
                                        sx={{ width: '50%' }}
                                        options={templateOptions}
                                        value={selectedTemplate}
                                        onChange={(event, newValue) => {
                                            setSelectedTemplate(newValue || '');
                                            // Trigger the same logic as original handleTemplateChange
                                            const syntheticEvent = {
                                                target: { value: newValue || '' }
                                            };
                                            handleTemplateChange(syntheticEvent);
                                        }}
                                        renderInput={(params) => (
                                            <TextField 
                                                {...params} 
                                                label="Select a template" 
                                                variant="outlined"
                                                required
                                            />
                                        )}
                                        getOptionLabel={(option) => option}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        noOptionsText="No templates found"
                                    />
                                    <Typography variant="body2" sx={{ marginTop: 1, color: 'gray' }}>
                                        Note: Only marketing templates are available
                                    </Typography>
                                </Grid>
                                
                                <Grid item xs={12} sx={{ marginTop: 2 }}>
                                    {mediaFile && (
                                        <FileUploadDetails />
                                    )}
                                </Grid>

                                {requiredFieldsToRender}

                                <Button 
                                    variant="contained" 
                                    sx={{ marginTop: 2 }} 
                                    type="submit"
                                >
                                    Create Campaign
                                </Button>
                            </form>
                            
                            {selectedTemplate && (

                                <form onSubmit={handleSubmit}>

                                    {showCampaignOptions && (
                                        <Grid container direction="column" sx={{ marginTop: 1 }}>
                                            <Divider sx={{ width: '100%', mt: 2 }} />

                                            <Typography variant="subtitle1" sx={{ mt: 2 , mb: 1}}>
                                                Select Campaign Type:
                                            </Typography>
                                            <RadioGroup
                                                value={selectedCampaign}
                                                onChange={(e) => setSelectedCampaign(e.target.value)}
                                            >
                                                <FormControlLabel
                                                    value="option1"
                                                    control={<Radio />}
                                                    label="Upload Audience using CSV"
                                                />
                                                <FormControlLabel
                                                    value="option2"
                                                    control={<Radio />}
                                                    label="Send templates using segments"
                                                />
                                                <FormControlLabel
                                                    value="option3"
                                                    control={<Radio />}
                                                    label="Send template to a single contact"
                                                />
                                            </RadioGroup>
                                        </Grid>
                                    )}

                                    <Divider sx={{ width: '100%', mt: 2 }} />

                                    {selectedCampaign === 'option1' && (    // Option 1: Upload CSV
                                        <UploadCSV 
                                            campaignName={campaignName}
                                            setCampaignName={setCampaignName}
                                            campaignDescription={campaignDescription}
                                            setCampaignDescription={setCampaignDescription}
                                            csvFile={csvFile}
                                            setCsvFile={setCsvFile}
                                            campaignsData={campaignsData}
                                        />
                                    )}

                                    {selectedCampaign === 'option2' && (    // Option 2: Select Segment
                                        <SelectSegment
                                            campaignName={campaignName}
                                            setCampaignName={setCampaignName}
                                            campaignDescription={campaignDescription}
                                            setCampaignDescription={setCampaignDescription}
                                            selectedSegment={selectedSegment}
                                            setSelectedSegment={setSelectedSegment}
                                            selectedSegmentList={selectedSegmentList}
                                            setSelectedSegmentList={setSelectedSegmentList}
                                            phoneNumberList={phoneNumberList}
                                            setPhoneNumberList={setPhoneNumberList}
                                            campaignsData={campaignsData}
                                        />
                                    )}

                                    {selectedCampaign === 'option3' && (    // Option 3: Single Contact
                                        <SingleContact
                                            setPhoneNumber={setPhoneNumber}
                                        />
                                    )}

                                    {selectedCampaign !== '' && (
                                        <Button variant="contained" type="submit" sx={{ marginTop: 2 }} >
                                            Broadcast
                                        </Button>
                                    )}
                                
                                </form>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <MessageNode 
                            templateName={templatePreviewData ? templatePreviewData.template_name : ''}
                            language={templatePreviewData ? templatePreviewData.language : 'english'}
                            headerType={templatePreviewData ? templatePreviewData.headerType : 'none'}
                            headerText={templatePreviewData ? templatePreviewData.headerText : ''}
                            headerFile={mediaFile ? mediaFile : templatePreviewData ? templatePreviewData.headerFile : null}
                            body={templatePreviewData ? templatePreviewData.body : 'No template selected'}
                            sampleBodyFields={templatePreviewData ? templatePreviewData.sampleBodyFields : []}
                            footer={templatePreviewData ? templatePreviewData.footer : ''}
                            allButtons={templatePreviewData ? templatePreviewData.allButtons : []}
                            mode='view'
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                    {alertObj.active && (
                        <Alert severity={alertObj.type} onClose={handleAlertClose}>
                        {alertObj.msg}
                        </Alert>
                    )}
                </Grid>
            </div>
        </Container>
    );
};
export default BroadcastTemplates;
