import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, IconButton, Avatar, Alert,  Stack, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemText, ListItemIcon } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import {
    FormatItalic,
    FormatBold,
    StrikethroughS,
    Code,
    FormatQuote,
    FormatListBulleted,
    FormatListNumbered,
  } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import ChatIcon from '@mui/icons-material/Chat';
import { useChatContext } from '../hooks/ChatProvider';
import { blue } from '@mui/material/colors';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import EmojiPicker from 'emoji-picker-react';
import timezone from "dayjs/plugin/timezone";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import TranslateIcon from '@mui/icons-material/Translate';
// import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { CHAT_START_TOPIC_ENDPOINT, CHAT_END_TOPIC_ENDPOINT } from '../config/urls';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useAuth } from "../hooks/AuthProvider";

// Initialize plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Set default timezone
dayjs.tz.setDefault("Asia/Kolkata");

const ChatWindow = () => {
    const { selectedUser, setChatData } = useChatContext();
    console.log("Selected User ", selectedUser);

    const [data, setData] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [isEndChatModalOpen, setIsEndChatModalOpen] = useState(false);
    const [selectedTone, setSelectedTone] = useState("");
    const { getValidToken } = useAuth();
    const socket = useRef(null);
    const [modalOpen, setModalOpen] = useState(false);  
    const [responseText, setResponseText] = useState("");
    const [totalCreditLeft, setTotalCreditLeft] = useState(0);
    
    const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:8000/api`;
    const NEWURL = process.env.NODE_ENV === 'production' ? '' : `http://localhost:8000`;

    const adminSubscriberCode = localStorage.getItem("code");
    const [open, setOpen] = React.useState(false);

    const parseMessage = (message) => {
        // Replace markdown-like syntax with HTML tags
        const formattedMessage = message
            .replace(/_([^_]+)_/g, '<i>$1</i>') // Italic: _text_
            .replace(/\*([^\*]+)\*/g, '<b>$1</b>') // Bold: *text*
            .replace(/~([^~]+)~/g, '<s>$1</s>') // Strikethrough: ~text~
            .replace(/`([^`]+)`/g, '<code>$1</code>'); // Strikethrough: ~text~
    
        return formattedMessage;
    };

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
    const openAIprompt = Boolean(anchorEl);

    const onEmojiClick = (emojiObject) => {
        setInputValue((prev) => prev + emojiObject.emoji);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
      };
    
    const handleInsert = () => {
        setInputValue(responseText); // Insert the transformed text into the textarea
        setModalOpen(false);
      };

    const handleMenuItemClick = async (tone) => {
        const currentText = inputValue;
    
        try {
          // Call API with current text and selected tone
          const requestUrl = `${URL}/transform-text/`;
          const token = await getValidToken();

            const headers = {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            };
          const response = await fetch(requestUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
              prompt: currentText,
              tone: tone.toLowerCase(),
            }),
          });
    
          const data = await response.json();
          console.log(data);
          // Update modal state with response
          setResponseText(data.enhanced_text  || "");
          setSelectedTone(tone);
          setTotalCreditLeft(data.remaining_tokens);
          setModalOpen(true);
        } catch (error) {
          console.error("Error calling the API:", error);
        } finally {
          handleMenuClose();
          handleSubmenuClose();
        }
      };

    useEffect(() => {   
        if (socket.current) {
            socket.current.close();
        }

        if (selectedUser) {
            setData([]);
            const socketURL = process.env.NODE_ENV === 'production' ? `wss://dev.chatbot.bizcollab360.com/ws/chat/${selectedUser.topic}/?sub=${adminSubscriberCode}` : `ws://localhost:8000/ws/chat/${selectedUser.topic}/?sub=${adminSubscriberCode}`;

            socket.current = new WebSocket(socketURL);

            socket.current.onopen = () => {
                console.log("WebSocket connection opened ...");
            };

            socket.current.onmessage = (event) => {
                if (event && event.data) {
                    const message = JSON.parse(event.data);
                    if (Array.isArray(message)) {
                        setData(message);
                    } else {
                        setData((prevData) => [...prevData, message]);
                    }
                }
            };

            socket.current.onerror = () => {
                console.error("WebSocket error");
            };

            socket.current.onclose = () => {
                console.log("WebSocket connection closed");
            };
        }

        return () => {
            if (socket.current) {
                socket.current.close();
            }
        };
    }, [selectedUser]);

    const handleOnClick = () => {
        if (inputValue.trim() !== "" && socket.current && socket.current.readyState === WebSocket.OPEN) {
            const newMessage = {
                user: adminSubscriberCode,
                message: inputValue,
                timestamp: new Date().toISOString(),
            };
            try {
                socket.current.send(JSON.stringify(newMessage));
                console.log("Message sent:", newMessage);
                setInputValue("");
            } catch (error) {
                console.error("Error sending message:", error);
            }
        } else {
            console.error("WebSocket not open or input value is empty.");
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleOnClick();
        }
    };

    // class handleMenuList = () => {

    // }

   
  const handleAIPromptClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAIPromptClose = () => {
    setAnchorEl(null);
  };
    const openEndChatModal = () => {
        setIsEndChatModalOpen(true);
    };

    const closeEndChatModal = () => {
        setIsEndChatModalOpen(false);
    };

    const handleSubmenuOpen = (event) => {
        setSubmenuAnchorEl(event.currentTarget);
    };

    const handleSubmenuClose = () => {
        setSubmenuAnchorEl(null);
    };

    const handleStartChat = async () => {
        try {
            const response = await fetch(`${CHAT_START_TOPIC_ENDPOINT}${selectedUser.topic}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code: adminSubscriberCode }),
            });

            if (response.ok) {
                console.log("Chat started successfully");
                setChatData(null);
            } else {
                console.error("Failed to start chat");
            }
        } catch (error) {
            console.error("Error starting chat:", error);
        }
    };

    const confirmEndChat = async () => {
        try {
            const response = await fetch(`${CHAT_END_TOPIC_ENDPOINT}${selectedUser.topic}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ is_active: false }),
            });

            if (response.ok) {
                console.log("Chat ended successfully");
                setChatData(null);
                // setSelectedUser(null);
               
            } else {
                console.error("Failed to end chat");
            }
        } catch (error) {
            console.error("Error ending chat:", error);
        } finally {
            closeEndChatModal();
            setChatData(null);
            // setSelectedUser(null);
        }
    };

    return (
        <Box display="flex" flexDirection="column" height="100%">
            <Box padding={2} borderBottom="1px solid #ccc" display="flex" alignItems="center">
                <Avatar><PersonIcon /></Avatar>
                <Typography variant="h6" component="div" marginLeft={2} flexGrow={1}>
                    {selectedUser ? `Chat with +${selectedUser.phone}` : `Chat with User`}
                </Typography>
                {selectedUser && selectedUser.supportUser ? (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={openEndChatModal}
                        startIcon={<ChatIcon />}
                        disabled={selectedUser.supportUser.code === adminSubscriberCode ? false : true}
                    >
                        End Chat
                    </Button>
                ) : (
                    <Button variant="contained" color="primary" onClick={handleStartChat} startIcon={<ChatIcon />}>
                        Start Chat 
                    </Button>
                )}
            </Box>
            <Box flex="1" overflow="auto" padding={4}>
                {data.map((message, index) => (
                    <Box key={index} marginBottom={2} display="flex" flexDirection={message.utype === "SPT" ? 'row-reverse' : 'row'}>
                        <Box maxWidth="70%" bgcolor={message.utype === "SPT" ? blue[500] : "#e0e0e0"} padding={1} borderRadius={3} position="relative" sx={{ wordBreak: 'break-word' }}>
                            <Stack spacing={0.5} direction="column">
                                <Typography wrap sx={{ fontSize: 14, textAlign: "left" }} color={message.utype === "SPT" ? "white" : "black"} dangerouslySetInnerHTML={{ __html: parseMessage(message.message) }} />
                                <Stack direction="row-reverse">
                                    <Typography wrap sx={{ fontSize: 10 }} color={message.utype === "SPT" ? "white" : "black"}>{dayjs(message.timestamp).format('hh:mm A')}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>
                ))}
            </Box>
            
            <Box display="flex" padding={2} borderTop="1px solid #ccc">
                <TextField
                    fullWidth
                    placeholder="Type a message"
                    variant="outlined"
                    size="small"
                    multiline="true"
                    rows={4 }
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    disabled={!selectedUser || !selectedUser.supportUser || (selectedUser.supportUser.code !== adminSubscriberCode)}
                />
                
                <IconButton color="primary" onClick={handleOnClick} disabled={!selectedUser || !inputValue.trim()}>
                    <SendIcon />
                </IconButton>
            </Box>
            <Box display="flex" padding={1}>
                <IconButton color="primary" onClick={handleOnClick} disabled={!selectedUser || !inputValue.trim()}>
                    <FormatBold />
                </IconButton>
                <IconButton color="primary" onClick={handleOnClick} disabled={!selectedUser || !inputValue.trim()}>
                    <FormatItalic />
                </IconButton>
                <IconButton color="primary" onClick={handleOnClick} disabled={!selectedUser || !inputValue.trim()}>
                    <StrikethroughS />
                </IconButton>
                <IconButton onClick={handleAIPromptClick} disabled={!selectedUser || !inputValue.trim()}>
                    <AutoFixHighIcon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openAIprompt}
                    onClose={handleAIPromptClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >   
                    {/* <MenuItem disabled={true}>Change Tone</MenuItem> */}
                    
                    <MenuItem onClick={handleSubmenuOpen}>
                        <ListItemText primary="Change Tone" />
                        <ListItemIcon sx={{ minWidth: 'unset' }}>
                            <ArrowRightIcon />
                        </ListItemIcon>
                    </MenuItem>
                        <Menu
                            anchorEl={submenuAnchorEl}
                            open={Boolean(submenuAnchorEl)}
                            onClose={handleSubmenuClose}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        >
                            <MenuItem onClick={() => handleMenuItemClick("professional")}>Professional</MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick("friendly")}>Friendly</MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick("casual")}>Casual</MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick("polite")}>Polite</MenuItem>
                        </Menu>
                    <Divider />
                    <MenuItem onClick={() => handleMenuItemClick("shorten")}>Shorten Text</MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick("lengthen")}>Elaborate Text</MenuItem>
                    <Divider />
                    <MenuItem onClick={() => handleMenuItemClick("simplify")}>Simplify</MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick("grammar_check")}>Fix Spell and Grammer</MenuItem>
                </Menu>
                
                {showEmojiPicker && (
                    <Box position="absolute" bottom="70px" right="600px">
                        {/* <EmojiPicker onEmojiClick={onEmojiClick} /> */}
                        <EmojiPicker reactionsDefaultOpen={true} onReactionClick={onEmojiClick} onEmojiClick={onEmojiClick} />
                    </Box>
                    )}
                <IconButton onClick={() => alert('Translate Icon clicked')} disabled={!selectedUser || !inputValue.trim()}>
                    <TranslateIcon />
                </IconButton>
                <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)} disabled={!selectedUser || !inputValue.trim()}>
                    <InsertEmoticonIcon />
                </IconButton>
                
            </Box>
            <Dialog open={isEndChatModalOpen} onClose={closeEndChatModal}>
                <DialogTitle>End Chat</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you want to end the chat with {selectedUser ? `+${selectedUser.phone}` : 'this user'}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEndChatModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmEndChat} color="error">
                        End Chat
                    </Button>
                </DialogActions>
            </Dialog>

            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 800,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 3,
                }}
                >
                <h2>Transformed Text</h2>
                <Alert  severity="info" sx={{my:2}}>
                    {`Hey you have total ${totalCreditLeft} credit left, please contact admin to get more credit`}
                </Alert>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={responseText}
                    onChange={(e) => setResponseText(e.target.value)}
                />
                <Button variant="contained" onClick={handleInsert} sx={{ mt: 2 }}>
                    Insert
                </Button>
                </Box>
            </Modal>

        </Box>
    );
};

export default ChatWindow;
