import React, { useState } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, TextField, InputAdornment } from '@mui/material';

const ZoneSearchInput = ({ updateSearchValue, updateRefreshKey }) => {
  const [showInput, setShowInput] = useState(false);
  const [query, setQuery] = useState('');

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSearch = () => {
    updateSearchValue(query.trim());
  };

  const handleSearchIconClick = () => {
    setShowInput(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleClearInput = () => {
    setQuery('');
    updateSearchValue(''); // Reset the search value to empty string
    updateRefreshKey(key => key + 1);// setShowInput(false); // Hide the input field after clearing
  };

  return (
    <div className='search-row'>
      {showInput ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            label="Search Zone, Subzone"
            variant="outlined"
            value={query}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            size="small"
            sx={{ mb: 2, width: '500px' }}
            autoFocus
            InputProps={{
              endAdornment: query && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearInput}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      ) : (
        <IconButton onClick={handleSearchIconClick} color="primary">
          <SearchOutlinedIcon />
        </IconButton>
      )}
    </div>
  );
};

export default ZoneSearchInput;
